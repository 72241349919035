let flag = 0;
let counter = 0;
const removeAllActiveLinks = () => {
  const nav = document.querySelectorAll('.nav-li');
  Array.from(nav).forEach(navLink => {
    navLink.classList.remove('active');
  });
};

const stickyHeader = () => {
  const navbar = document.getElementById('navbar');
  const nonSticky = document.querySelector('#navbar.non-sticky');
  const sticky = document.querySelector('#navbar.sticky');

  const stickyAll = document.querySelector('#navbar.sticky > *');
  const navHome = document.querySelector('.non-sticky #nav-home');
// Get the offset position of the navbar
  if (sticky === null) {
    return;
  }

  const stickyOffset = navbar.offsetTop;
  if (window.pageYOffset - 40 > stickyOffset) {
    nonSticky.style.opacity = 0;
    sticky.style.opacity = 1;
    stickyAll.style.opacity = 1;
    sticky.style.height = '70px';
  } else {
    nonSticky.style.opacity = 1;
    navHome.classList.add('active');
    sticky.style.opacity = 0;
    stickyAll.style.opacity = 0;
    sticky.style.height = 0;
  }
};

const onScroll = () => {
  let scrollPos = document.documentElement.scrollTop;
  const nav = document.querySelectorAll('.nav-li');
  if (!flag) {
    Array.from(nav).forEach(navLink => {
      let target = navLink.getAttribute('id');
      let targetId = target.replace('nav-', '');
      let elem = document.getElementById(targetId);

      if (elem === null) {
        return;
      }
      if (elem.offsetTop - 50 <= scrollPos && elem.offsetTop + elem.offsetHeight > scrollPos) {
        removeAllActiveLinks();
        navLink.classList.add('active');
      } else if (targetId === 'careers' && (window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        removeAllActiveLinks();
        navLink.classList.add('active');
      }
    });
  }
  checkAnimateCounter();
};

const checkAnimateCounter = () => {
  let scrollPos = document.documentElement.scrollTop;
  let elem = document.getElementById('milestones');
  if (elem === null) {
    return;
  }
  const countBoxes = document.querySelectorAll('.mile-title .count');
  if (elem.offsetTop - 400 <= scrollPos && elem.offsetTop + elem.offsetHeight > scrollPos && !counter) {
    Array.from(countBoxes).forEach(box => {
      let value = parseInt(box.innerHTML);
      animateValue(box, 1, value, value > 500 ? 100 : (value < 10 ? 0.1 : 1), 2);
    });

  }
};

const scrollToView = (targetId) => {
  const position = document.getElementById(targetId).offsetTop;
  window.scrollTo({ top: position - 20, behavior: 'smooth' });

};

const navAction = () => {

  const stickyLinks = document.querySelectorAll('.sticky .nav-li');
  Array.from(stickyLinks).forEach(link => {

    link.addEventListener('click', () => {
      flag = 1;
      removeAllActiveLinks();
      link.classList.add('active');
      let target = link.getAttribute('id');
      let targetId = target.replace('nav-', '');

      scrollToView(targetId);
      setTimeout(function() {
        flag = 0;
      }, 400);
      //
    });
  });
};

const animateValue = (box, start, end, increment, duration) => {
  let current = 0;
  let total = (end - start) / increment;
  let interval = (total) * (total >= 10 ? 2 : 8) / duration;
  let timer = setInterval(function() {
    current += increment > 1 ? increment : 1;
    box.innerHTML = current;
    if (current >= end) {
      clearInterval(timer);
    }
  }, interval);
  counter = 1;
};

const getSelectors = (buttonId, loaderId) => {
  return { text: document.querySelector(buttonId), spinner: document.querySelector(loaderId) };
};

const showSpinner = (buttonId, loaderId) => {
  const { text, spinner } = getSelectors(buttonId, loaderId);
  if (text && spinner) {
    text.disabled = true;
    spinner.style.display = 'block';
  }
};

const hideSpinner = (buttonId, loaderId) => {
  const { text, spinner } = getSelectors(buttonId, loaderId);
  if (text && spinner) {
    text.disabled = false;
    spinner.style.display = 'none';
  }
};

const verifyResumeFormat = ({ fileName }) => {
  if (!fileName || fileName.indexOf('.') < 0)
    return '';

  return fileName.split('.').pop().toLowerCase();
};

const getFileExtension = ({ fileName }) => {
  if (fileName.indexOf('.') < 0) {
    return '';
  }
  return fileName.split('.').pop();
};

module.exports = {
  stickyHeader,
  navAction,
  scrollToView,
  onScroll,
  checkAnimateCounter,
  showSpinner,
  hideSpinner,
  getFileExtension,
  verifyResumeFormat,
};