import React from 'react';
import CareerInputForm from './Career/input-form';
import CareerSuccessForm from './Career/success-form';
import { showSpinner, hideSpinner } from '../utils';
import RequestModal from './request-modal';
import { CAREERS_API, RESUME_UPLOAD_API, RESUME_UPLOAD } from '../constants';
import { verifyResumeFormat } from '../utils';

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      fullName: '',
      mobileNumber: '',
      resume: '',
      fileName: 'Attach Resume*',
      selected: '',
      submit: '',
      submitted: false,
      departments: ['Sales', 'Marketing', 'Finance', 'HR & Admin', 'IT', 'Product Management', 'Engineering & Tech', 'Lab technician', 'Customer Service', 'Data Entry', 'Procurement/Purchase', 'Pharmacist', 'Others'],
      selectedDepartment: 'Departments*',
      buttonId: '.apply-button .btn',
      loaderId: '.apply-button .loading',
      isOpen: false,
      error: false,
      errorMsgFileFormat: false,
      errorMsgMb: false,
      errorMsgEmail: false,
    };
  }

  checkForm = () => {
    const { email, fullName, mobileNumber, selectedDepartment, resume } = this.state;

    let phoneRegex = /^[6789]\d{9}$/;
    let emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
    if (!mobileNumber.match(phoneRegex) && mobileNumber.length >= 10) {
      this.setState({ errorMsgMb: true });
    } else {
      this.setState({ errorMsgMb: false });

    }
    if (!email.match(emailRegex) && email.length !== 0) {
      this.setState({ errorMsgEmail: true });
    } else {
      this.setState({ errorMsgEmail: false });
    }
    if (email.match(emailRegex) && fullName && mobileNumber.match(phoneRegex) && selectedDepartment !== 'Departments*' && resume) {
      this.setState({
        submit: 'submit',
      });
    } else {
      this.setState({
        submit: '',
      });
      }
  };

  checkResumeFormat = (file) => {
    const format = verifyResumeFormat({ fileName: file });
    return RESUME_UPLOAD.indexOf(format) >= 0;
  };


  onChange = (e) => {
    if (e.target.id === 'file') {

      this.setState({ errorMsgFileFormat: false });
      const check = this.checkResumeFormat(e.target.files[0].name);
      if (check) {
        this.setState({
          fileName: e.target.files[0].name,
          resume: e.target.files[0],
          selected: 'selected',
        }, this.checkForm);

        document.querySelector('.input-file').classList.remove('fileNotExists');
        document.querySelector('.input-file').classList.add('fileExists');
      } else {
        document.querySelector('.input-file').classList.remove('fileExists');
        document.querySelector('.input-file').classList.add('fileNotExists');
        this.setState({
          errorMsgFileFormat: true,
          fileName: e.target.files[0].name,
          resume: '',
        }, this.checkForm);
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      }, this.checkForm);
      if (e.target.name === 'selectedDepartment' && e.target.value !== 'Departments*') {
        document.querySelector('.career-input-wrap select').classList.remove('light');
      } else if (e.target.name === 'selectedDepartment' && e.target.value === 'Departments*') {
        document.querySelector('.career-input-wrap select').classList.add('light');
      }
    }
  };

  closeModal = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  onSubmit = () => {
    const { fullName, email, mobileNumber, selectedDepartment, resume, buttonId, loaderId } = this.state;
    try {
      showSpinner(buttonId, loaderId);
      const form = new FormData();
      form.append('file', resume);
      form.append('name', fullName);
      fetch(RESUME_UPLOAD_API, {
        method: 'post',
        body: form,
      }).then(this.handleErrors)
        .then((response) => {
          if (response.status === 200) {
            return response.json().then((responseData) => {
              const data = {
                name: fullName,
                email,
                phoneNumber: mobileNumber,
                department: selectedDepartment,
                filePath: responseData.data,
              };
              fetch(CAREERS_API, {
                method: 'post',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
              }).then(this.handleErrors)
                .then((response) => {
                  if (response.status === 200) {
                    this.setState({
                      submitted: true,
                    });
                    hideSpinner(buttonId, loaderId);
                  }

                })
                .catch((error) => {
                  this.setState({
                    isOpen: true,
                    error: true,
                  });
                  hideSpinner(buttonId, loaderId);
                });
            });
          } else {
            this.setState({
              isOpen: true,
              error: true,
            });
            hideSpinner(buttonId, loaderId);
          }
        })
        .catch((error) => {
          this.setState({
            isOpen: true,
            error: true,
          });
          hideSpinner(buttonId, loaderId);
          throw error;
        });
    } catch (error) {
      this.setState({
        isOpen: true,
        error: true,
      });
      hideSpinner(buttonId, loaderId);
    }
  };

  onRequest = () => {
    this.setState({
      email: '',
      fullName: '',
      mobileNumber: '',
      selectedDepartment: 'Departments*',
      resume: '',
      fileName: 'Attach Resume',
      selected: '',
      submit: '',
      submitted: false,
    });
  };

  render() {
    const {
      email,
      fullName,
      mobileNumber,
      fileName,
      selected,
      submit,
      submitted,
      departments,
      selectedDepartment,
      isOpen,
      error,
      errorMsgFileFormat,
      errorMsgEmail,
      errorMsgMb,

    } = this.state;
    return (
      <div className='career-form'>
        <RequestModal
          isOpen={isOpen}
          closeModal={this.closeModal}
          errorForm={error}
        />
        {!submitted ? (
            <CareerInputForm
              email={email}
              fullName={fullName}
              mobileNumber={mobileNumber}
              fileName={fileName}
              selected={selected}
              submit={submit}
              departments={departments}
              selectedDepartment={selectedDepartment}
              onChange={this.onChange}
              onSubmit={this.onSubmit}
              errorMsgFileFormat={errorMsgFileFormat}
              errorMsgEmail={errorMsgEmail}
              errorMsgMb={errorMsgMb}

            />
          ) :
          <CareerSuccessForm onSubmit={this.onRequest}/>
        }
      </div>
    );
  }
}

export default Form;