import React from 'react';
import SectionTitle from '../section-title';
import Image from '../image';
import IconWithText from './icon-with-text';
import RequestButton from '../request-button';

const ImageWithTextIcon = ({ type, title, description, image }) => {
  const modalTitle = type === 'hospital' ? 'Join the most trusted network of Hospitals' : 'Join the most trusted network of Doctors';
  return (
    <section className='row' id={type}>
      <SectionTitle title={title} description={description} customStyle='center mobile'/>
      <div className='col-md-6 image'>
        <Image src={image}/>
      </div>
      <div className='col-md-6 content-wrapper'>
        <SectionTitle title={title} description={description} customStyle='left'/>
        <IconWithText type={type}/>
        <RequestButton type={type} title={modalTitle}/>
      </div>
    </section>
  );
};

export default ImageWithTextIcon;
