import React from 'react';
import Image from './image';

const Banner = () => (
  <div className='row banner-outer' id="home">
    <div className='col-md-4 col-sm-12 banner-content'>
      <div className='banner-title'>AI Powered Products For Doctors</div>
      <div className='banner-description'>Transform your clinics/hospitals into ‘Smart Clinics’ and ‘Smart Hospitals’
      </div>
      <button type="button" className="btn btn-primary">Know More</button>
    </div>
    <div className='col-md-8 col-sm-12 image-banner'>
      <Image src='banner_img.png'/>
    </div>
  </div>
);

export default Banner;

