import React from 'react';
import MileStone from './milestone-box';
import SectionTitle from '../section-title';

const MileStones = () => (
  <section id="milestones">
    <SectionTitle title='Milestones Achieved' customStyle='center'/>
    <div className='container-fluid'>
      <div className='milestone row'>
        <MileStone countValue='20' countText='&nbsp;Lacs' name='Prescription Digitized' type='prescription'/>
        <MileStone countValue='2000' countText='+' name='Doctors' type='doctor'/>
        <MileStone countValue='24' name='Specialities' type='specialities'/>
        <MileStone countValue='6' name='Cities' type='cities'/>
      </div>
    </div>
  </section>
);

export default MileStones;
