import React from 'react';

const MileStone = ({ countValue, countText, name, type }) => {
  return (
    <div className='col-lg-3 col-md-6 col-6'>
      <div>
        <div className={`mile-title ${type}`}>
          <span className='count'>{countValue} </span>
          <span className='count-text'>{countText}</span>
        </div>
        <div className='mile-desc'>
          {name}
        </div>
      </div>
    </div>
  );
};

export default MileStone;
