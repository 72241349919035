import React from 'react';

const CareerSuccessForm = ({onSubmit}) => {
    return(
        <div className='success-content'>
            <div className='success-title'>Applied Successfully!</div>
            <div className='success-description'>Thanks for submitting your application. We will get back to you for further steps.</div>
            <div className='row center success-button'>
                <input type='button' className='btn submit' value='Apply For Another Post' onClick={onSubmit} />
            </div>
        </div>
    )
};

export default CareerSuccessForm;
