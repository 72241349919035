import React from 'react';
import PropTypes from 'prop-types';
import Header from './header';
import {Footer} from '@medtrail/shared-ui';
import './layout.css';
import { ROUTE_FLAG, WEBSITE } from '../constants';

const Layout = ({ children }) => {
  return (
    <>
      <Header customStyle='non-sticky' active='active' routeFlag={ROUTE_FLAG.homePage}/>
      <Header customStyle='sticky' active='active' routeFlag={ROUTE_FLAG.homePage}/>
      <main>{children}</main>
      <Footer website={WEBSITE}/>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
