import React from 'react';
import FeatureBox from "./feature-box";
import HospitalFeatureBox from "./feature-box-hospital";

const IconWithText = ({type}) => (
    <div className='features'>
        {type === 'doctor'?
            <>
                <div className='row'>
                    <FeatureBox title='Electronic Medical Records' description="Your patients’ medical records appear automatically in your mobile app" type='medical'/>
                    <FeatureBox title='Patient Outreach' description='Send digitized prescriptions and educational content to patients in real time' icon='patient'/>
                </div>
                <div className='row'>
                    <FeatureBox title='Prescription Analytics' description='Track medicines and tests that you prescribe' type='prescription'/>
                </div>
            </>:
            <>
                <div className='row'>
                    <HospitalFeatureBox title='Electronic Medical Records' description='Digitized medical records help in regulatory compliance, quality audits and improved patient experience' type='medical'/>
                    <HospitalFeatureBox title='Cross Departmental Collaboration' description="Digitized prescriptions sent to Hospital Integration Systems and different departments in real time" type='prescription'/>
                </div>
                <div className='row'>
                    <HospitalFeatureBox title='Data Based Decision Making' description='Automated prescription analytics drive effective decision making and improve hospitals’ practices' icon='patient'/>
                </div>
            </>
        }

    </div>
)

export default IconWithText;