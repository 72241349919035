import React from 'react';
import PrescriptionIcon from "../../images/vectors/prescription_icon.svg";
import PatientIcon from "../../images/vectors/patient_outreach.svg";
import ElectronicMedicalIcon from "!svg-react-loader!../../images/vectors/electronic_medical.svg";

const FeatureBox = ({ title, description, type }) => {
    return (
        <div className='feature-container col-md-6 col-sm-12'>
            <div className='feature-icon'>
                {type === 'prescription'? <PrescriptionIcon/> : (type === 'medical' ? <ElectronicMedicalIcon/> : <PatientIcon/>)}
            </div>
            <div className='feature-content'>
                <div className='title'>{title}</div>
                <div className='description'>{description}</div>
            </div>
        </div>
    );
};

export default FeatureBox;
