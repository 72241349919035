import React from 'react';
import SectionTitle from './section-title';
import Image from './image';

const SmartAssistant = () => (
  <section id='smart-assistant'>
    <SectionTitle title='Smart Assistant'
                  description='AI powered smart assistant that digitize your handwritten prescriptions with zero effort'
                  customStyle='center'/>
    <div className='image-wrapper'>
      <Image src='smart-assistant.png'/>
    </div>
    <div className='image-wrapper mobile'>
      <Image src='smart-assistant-mobile.png'/>
    </div>
  </section>
);

export default SmartAssistant;
