import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import SectionTitle from '../components/section-title';
import Form from '../components/form';
import Banner from '../components/banner';
import ImageWithTextIcon from '../components/Features';
import SmartAssistant from '../components/smart-assistant';
import MileStones from '../components/Statistics/milestones';
import { navAction, stickyHeader, scrollToView, onScroll, checkAnimateCounter } from '../utils';

class IndexPage extends React.Component {

  componentDidMount() {

    if (window.getComputedStyle(document.querySelector('.nav-ul')).getPropertyValue('display') !== 'none') {
      stickyHeader();
      onScroll();
      window.onscroll = () => {
        stickyHeader();
      };
      document.onscroll = () => {
        onScroll();
      };
      navAction();
    } else {
      document.onscroll = () => {
        checkAnimateCounter();
      };
    }
    document.querySelector('.banner-content button').addEventListener('click', () => {
      scrollToView('smart-assistant');
    });
  }

  render() {
    return (
      <Layout>
        <div id='main' className='container-fluid'>
          <SEO title="Home"/>
          <Banner/>
          <SmartAssistant/>
          <MileStones/>
          <ImageWithTextIcon
            type='doctor'
            title='For Doctors'
            description="Transform your clinic into 'Smart Clinic' without changing your workflow"
            image='doctor_group.png'
          />
          <ImageWithTextIcon
            type='hospital'
            title='For Hospitals'
            description="Add artificial intelligence to your IT infrastructure and transform your hospital into 'Smart Hospital'"
            image='hospital_group.png'
          />
          {/*<SectionStatistics/>*/}
          <section id='careers'>
            <SectionTitle title='Work with Us!' description='Fun and merit driven culture' customStyle='center'/>
            <Form/>
          </section>
        </div>
      </Layout>
    );
  }
}

export default IndexPage;
