import React from 'react';
import ErrorIcon from "!svg-react-loader!../../images/vectors/warning.svg";
import CloseIcon from "../../images/vectors/close_icon.svg";

const DemoErrorForm = ({onClose}) => {
    return(
        <div className='center' id='demo-error-form'>
            <div className='close-icon' align='right' onClick={onClose}>
                <CloseIcon/>
            </div>
            <ErrorIcon/>
            <div className='demo success-title'>Oops!</div>
            <div className='demo success-description'>For some reason, we couldn't process your request.</div>
        </div>
    )
}

export default DemoErrorForm;