import React from 'react';
import SuccessIcon from '!svg-react-loader!../../images/vectors/success_icon.svg';
import CloseIcon from '../../images/vectors/close_icon.svg';

const DemoSuccessForm = ({ onClick, onClose }) => {
  return (
    <div className='center' id='demo-success-form'>
      <div className='close-icon' align='right' onClick={onClose}>
        <CloseIcon/>
      </div>
      <SuccessIcon/>
      <div className='demo success-title'>Thank you!</div>
      <div className='demo success-description'>We will get back to you to schedule a demo.</div>
      <div className='input-btn'>
        <input type='button' value='Request Another Demo' className='btn submit' onClick={onClick}/>
      </div>
    </div>
  );
};

export default DemoSuccessForm;