import React from 'react';
import PropTypes from 'prop-types';

const SectionTitle = ({ title, description, customStyle }) => (
  <div className={`section-head-wrapper ${customStyle}`}>
    <div className='section-title'>{title}</div>
    <div className={`section-description ${customStyle === 'left' && 'col-md-7'}`}>{description}</div>
  </div>
);

SectionTitle.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

SectionTitle.defaultProps = {
  title: '',
  description: '',
};

export default SectionTitle;