import React from 'react';
import RequestModal from './request-modal';

class RequestButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  stopScrolling = () => {
    if (this.state.isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  };
  closeModal = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    }, this.stopScrolling);
  };

  render() {
    const { isOpen } = this.state;
    const { title, type } = this.props;
    return (
      <div className='demo-btn'>
        <RequestModal isOpen={isOpen} closeModal={this.closeModal} title={title} type={type}/>
        <button type="button" className="btn btn-primary" onClick={this.closeModal}>Request Demo</button>
      </div>
    );
  }
}

export default RequestButton;