import React from 'react';
// import AttachIcon from '../../images/vectors/attach_icon.svg';
// import AttachIcon from '../../images/vectors/attach_icon.svg';
import { getFileExtension } from '../../utils';

const modifyFileName = (fileName) => {
  let modifiedFileName = fileName;
  if (fileName && fileName.length > 15) {
    modifiedFileName = `${fileName.substring(0, 12)}...${getFileExtension({ fileName })}`;
  }
  return modifiedFileName;
};

const CareerInputForm = ({ fullName, mobileNumber, email, departments, selectedDepartment, fileName, selected, onChange, onSubmit, submit, errorMsgFileFormat, errorMsgEmail, errorMsgMb }) => {
  return (
    <form>
      <div className='row center career-input-wrap'>
        <div className='fullName col-lg-2 col-md-3 form-control'>
          <input type='text' placeholder='Full Name*' name='fullName'
                 value={fullName} onChange={onChange}/>
        </div>
        <div className='mobileNumber col-md-3 col-lg-2 form-control '>
          <input type='number' placeholder='Mobile No.*' name='mobileNumber'
                 value={mobileNumber} onChange={onChange}/>
          {errorMsgMb ? (<div className='mbErrorMsg'>Please enter a valid mobile number</div>
          ) : null}
        </div>
        <div className='email col-lg-2 col-md-3 form-control'>
          <input type='text' placeholder='Email*' name='email'
                 value={email} onChange={onChange}/>
          {errorMsgEmail ? (<div className='emailErrorMsg'>Please enter a valid email address</div>
          ) : null}
        </div>
      </div>
      <div className='row center career-input-wrap pdfRow'>
        <select value={selectedDepartment} className='form-control col-lg-2 col-md-3 light' name='selectedDepartment'
                onChange={onChange}>
          <option hidden>Departments*</option>
          {departments.map((item) => <option key={item} value={item}>{item}</option>)}
        </select>
        <div className='form-control input-file col-lg-2 col-md-3 fileExists' data-toggle="tooltip"
             data-placement="top" title={fileName}>
          <label htmlFor="file" className={`${selected} inputLabelPdf`}>{modifyFileName(fileName)}</label>
          <input type='file' id='file' accept="application/pdf" onChange={onChange}/>
          {errorMsgFileFormat ? (<div className='formatErrorMsg'>only .pdf and .docs file supported</div>
          ) : null}
        </div>
      </div>
      <div className='row center apply-button'>
        {/*<div className='mobErrorMsg'>Phone number is not valid</div>*/}
        <button type="button" className={`btn ${submit}`} onClick={onSubmit} disabled={!submit ? true : false}>
          Apply
          <div className="loader loading"></div>
        </button>
      </div>
    </form>
  );
};

export default CareerInputForm;
