import React from 'react';
import ReactModal from 'react-modal';
import DemoInputForm from './Demo/input-form';
import DemoSuccessForm from './Demo/success-form';
import DemoErrorForm from './Demo/error-form';
import { showSpinner, hideSpinner } from '../utils';
import { REQUEST_DEMO_API } from '../constants';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(115,121,141, 0.75)',
    zIndex: 9,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '438px',
    maxHeight: '550px',
    boxShadow: '0px 15px 26px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
    padding: 0,
    border: 0,
    errorMsgMbDemo: false,
  },
};

//ReactModal.setAppElement(document.createElement('div'));

class RequestModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      phoneNumber: '',
      city:'',
      submit: '',
      submitted: false,
      error: false,
      buttonId: '.input-btn .btn',
      loaderId: '.input-btn .loading',
    };
  }

  checkForm = () => {
    const { name, phoneNumber,city } = this.state;
    let phoneRegex = /^[6789]\d{9}$/;

    if (!phoneNumber.match(phoneRegex) && phoneNumber.length >= 10) {
      this.setState({ errorMsgMbDemo: true });
    } else {
      this.setState({ errorMsgMbDemo: false });
    }


    if (name && name.trim() && city && city.trim() && phoneNumber.match(phoneRegex) && phoneNumber.length === 10) {
      this.setState({
        submit: 'submit',
      });
    } else {
      this.setState({
        submit: '',
      });
    }
  };

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    }, this.checkForm);
  };

  handleErrors = (response) => {
    if (!response.ok) {
      throw Error('Error');
    }
    return response;

  };

  onSubmit = () => {
    const { name, phoneNumber,city, buttonId, loaderId } = this.state;
    const { type } = this.props;
    try {
      const data = {
        name,
        phoneNumber,
        city,
        flag: type,
      };
      showSpinner(buttonId, loaderId);
      fetch(REQUEST_DEMO_API, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }).then(this.handleErrors)
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              submitted: true,
            });
          } else {
            this.setState({
              error: true,
            });
          }
          hideSpinner(buttonId, loaderId);
        })
        .catch((error) => {
          this.setState({
            error: true,
          });
          hideSpinner(buttonId, loaderId);
        });
    } catch (e) {
      this.setState({
        error: true,
      });
      hideSpinner(buttonId, loaderId);
    }
  };

  onRequest = () => {
    this.setState({
      submitted: false,
      name: '',
      city:'',
      phoneNumber: '',
      submit: '',
      error: false,
      errorMsgMbDemo: false,
    });
  };

  onClose = () => {
    this.onRequest();
    this.props.closeModal();
  };

  render() {
    const { isOpen, title, errorForm } = this.props;
    const { name, phoneNumber, submit, submitted, error, errorMsgMbDemo,city } = this.state;
    return (
      <ReactModal
        isOpen={isOpen}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        {error || errorForm ? <DemoErrorForm onClose={this.onClose}/> : (!submitted ?
          <DemoInputForm fullName={name} mobileNumber={phoneNumber} city={city} title={title} submit={submit}
                         onChange={this.onChange} onSubmit={this.onSubmit} onClose={this.onClose}
                         errorMsgMbDemo={errorMsgMbDemo}/> :
          <DemoSuccessForm onClick={this.onRequest} onClose={this.onClose}/>)
        }
      </ReactModal>
    );
  }
};

export default RequestModal;
